import Apnotrack from "../../assets/apnotrackhub.svg";

// import ClionicBigLogo from '../../assets/asleep_logo.png';
import ButtonDefault from "../../components/ButtonDefault";

import Title from "../../components/Title";
import { Link } from "react-router-dom";

function FrontPage({
  title,
  note,
  btnDisabled,
  handleClick,
  reset,
  signUp,
  complete,
  correct,
  registered,
  pathName,
  handleVerifyCode,
  verifiedButtonTitle,
  ...props
}) {
  return (
    <div className="w-full h-screen flex flex-row items-center justify-center bg-cream">
      <div
        // onSubmit={handleClick}
        className="bg-white shadow-default border border-white w-3/4 md:w-1/2 lg:w-1/2 xl:w-[32%] 2xl:w-1/4 flex flex-col px-6 py-2 pt-8 gap-6"
      >
        <div className="flex justify-center items-center">
          <img src={Apnotrack} className="w-[217px]  px-8" alt="none" />
        </div>

        <div className="flex flex-col gap-2">
          <Title value={title} />
          <p className="">{note}</p>
        </div>

        <div className="flex flex-col gap-4 w-full">{props.children}</div>

        <div className="flex flex-col justify-center items-center gap-4">
          {/* <ButtonDefault
            type="submit"
            value={btnValue}
            size="small"
            width="medium"
            disabled={btnDisabled}
          /> */}
          {reset ? (
            // <div className="flex justify-end w-full">
            //   <ButtonDefault
            //     type="submit"
            //     value="SAVE"
            //     size="small"
            //     width="medium"
            //     disabled={btnDisabled}
            //     handleClick={handleClick}
            //   />
            // </div>
            complete ? (
              <div className="flex justify-center w-full">
                <ButtonDefault
                  type="submit"
                  value="LOG IN"
                  size="small"
                  width="medium"
                  handleClick={handleClick}
                />
              </div>
            ) : (
              <div className="flex justify-end w-full">
                <ButtonDefault
                  type="submit"
                  value="SAVE"
                  size="small"
                  width="medium"
                  disabled={btnDisabled || !correct}
                  handleClick={handleClick}
                />
              </div>
            )
          ) : pathName === "/signup" && !registered ? (
            <div className="flex justify-between w-full">
              <ButtonDefault
                type="submit"
                value="LOG IN"
                size="small"
                width="medium"
                handleClick={handleClick}
                onlyBorder={true}
              />

              <ButtonDefault
                type="submit"
                value="REGISTER"
                size="small"
                width="medium"
                disabled={btnDisabled}
                handleClick={handleClick}
              />
            </div>
          ) : pathName === "/admin/signup" && !registered ? (
            <div className="flex justify-between w-full">
              <ButtonDefault
                type="submit"
                value="LOG IN"
                size="small"
                width="medium"
                handleClick={handleClick}
                onlyBorder={true}
              />

              <ButtonDefault
                type="submit"
                value="REGISTER"
                size="small"
                width="medium"
                disabled={btnDisabled}
                handleClick={handleClick}
              />
            </div>
          ) : pathName === "/verify" && !registered ? (
            <div className="flex justify-end w-full">
              {/* <Link to={`/signup`}> */}
              <ButtonDefault
                type="submit"
                value={verifiedButtonTitle}
                size="small"
                width="medium"
                disabled={btnDisabled}
                handleClick={handleVerifyCode}
              />
              {/* </Link> */}
            </div>
          ) : (
            !registered && (
              <div className={`flex ${"justify-between"} w-full`}>
                <Link
                  to={`${pathName === "/admin" ? "/admin/signup" : "/verify"}`}
                >
                  <ButtonDefault
                    type="submit"
                    value="SIGNUP"
                    size="small"
                    width="medium"
                    hollow={true}
                    onlyBorder={true}
                    // handleClick={handleClick}
                  />
                </Link>

                <ButtonDefault
                  type="submit"
                  value="CONTINUE"
                  size="small"
                  width="medium"
                  disabled={btnDisabled}
                  handleClick={handleClick}
                />
              </div>
            )
          )}
          <div className="flex justify-between items-center w-full text-sm pt-3">
            <div className="flex gap-3">
              <a href={process.env.REACT_APP_TERMS_OF_USE_URL}>Terms of Use</a>
              <p>|</p>
              <a href={process.env.REACT_APP__PERSONAL_INFORMATION_RULES_URL}>
                Privacy Policy
              </a>
            </div>

            <a
              className="font-medium"
              href="https://www.asleep.ai/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://autosleepdiary.com/static/media/powered-by-icon.8fa8130548305fee2a9c0aff93e52137.svg"
                alt="asleep"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrontPage;
