import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FrontPage from "../../components/FrontPage";
import SignUp from "../../components/SignUp";
import InputDefault from "../../components/InputDefault";
import useValidatedState from "../../hooks/useValidatedState";

import ButtonDefault from "../../components/ButtonDefault";
import axios from "axios";
import Verify from "../../components/Verify";

function Account() {
  const navigate = useNavigate();
  const location = useLocation();
  // const [startDate, setStartDate] = useState(getDateBeforeNDays(14));
  //   const [endDate, setEndDate] = useState(getDateBeforeNDays(0));

  const [id, setId, idValidationMsg] = useValidatedState({
    required: true,
    fieldName: "email",
    type: "email",
  });
  const [password, setPassword, passwordValidationMsg] = useValidatedState({
    required: true,
    fieldName: "password",
    type: "password",
  });

  const [doctorId, setDoctorId] = useState("");

  const [inputValues, setInputValues] = useState({
    name: "",
    phone_number: "",
    email: "",
    password: "",
    hospital_name: "",
    license_number: "",
    code: "",
  });

  // const [startDate, setStartDate, startDateValidationMsg] = useValidatedState({
  //   required: false,
  //   fieldName: "start date",
  //   type: "date",
  //   defaultVal: getDateArray(getDateBeforeNDays(14)),
  // });
  // const [endDate, setEndDate, endDateValidationMsg] = useValidatedState({
  //   required: false,
  //   fieldName: "end date",
  //   type: "date",
  //   defaultVal: getDateArray(getDateBeforeNDays(0)),
  // });

  const [reset, setReset] = useState(false);
  const [complete, setComplete] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [changePswd, setChangePswd] = useState("");
  const [verifiedButtonTitle, setVerifiedButtonTitle] = useState("CONTINUE");

  const isAllInputValid = () => {
    let allFields = reset
      ? [passwordValidationMsg]
      : location.pathname === "/signup"
      ? [
          signUpRules.name ? undefined : "",
          signUpRules.email ? undefined : "",
          signUpRules.password ? undefined : "",
          signUpRules.hospital_name ? undefined : "",
          signUpRules.license_number ? undefined : "",
        ]
      : location.pathname === "/verify"
      ? [signUpRules.code ? undefined : ""]
      : [idValidationMsg, passwordValidationMsg];
    // console.log(allFields);
    for (let i of allFields) {
      if (i !== undefined) {
        return false;
      }
    }

    return true;
  };
  // const loginData = { username: id, password };
  const [loginFormData, setLoginFormData] = useState({
    email: id,
    password,
  });

  const handleSubmit = async (e) => {
    // e.preventDefault();

    if (e.target.value === "SAVE") {
      const accessToken = localStorage.getItem("access_token");
      //비밀번호 push하기
      try {
        await axios
          .post(
            `https://apnotrack-api.asleep.ai/api/v2/password-reset`,
            {
              id: doctorId,
              new_password: password,
              confirm_password: changePswd,
            },
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          )
          .then(setComplete(true));
      } catch (error) {
        console.log(error);
      }
    } else if (e.target.value === "LOG IN") {
      // PUT Method로 정보 수정하기.
      setComplete(false);
      setReset(false);
      setRegistered(false);
      setId("");
      setPassword("");
      setChangePswd("");
      setInputValues({
        name: "",
        email: "",
        password: "",
        hospital_name: "",
        license_number: "",
        phone_number: "",
        code: "",
      });

      // 상태 업데이트 후 네비게이션
      navigate("/"); // 원하는 경로로 변경
    } else if (e.target.value === "REGISTER") {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_APNOTRACK_URL}/v1/hub/auth/doctor/signup`,

          inputValues
        );
        console.log("Data updated successfully:", response.data);
        setRegistered(true);
        setInputValues({
          name: "",
          email: "",
          password: "",
          hospital_name: "",
          license_number: "",
          phone_number: "",
        });
      } catch (error) {
        console.error("Error updating data:", error.response.data.detail);

        if (error.response?.data?.detail === "Email already exists") {
          setInputValues({
            name,
            email: "",
            password,
            hospital_name,
            license_number,
            phone_number,
          });
        }
        alert(error.response.data.detail);
      }
    } else if (e.target.value === "CONTINUE") {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_APNOTRACK_URL}/v1/hub/auth/doctor/login`,
          loginFormData
        );

        const data = response.data.result;
        if (data.access_token) {
          localStorage.setItem("access_token", data.access_token);
          sessionStorage.setItem("refresh_token", data.refresh_token);
        }

        if (data.role === "Admin") {
          navigate("/admin/doctor-list");
        } else if (data.role === "Doctor" && data.last_login === null) {
          setPassword("");
          setDoctorId(data.id);
          setReset(true);
        } else {
          navigate("/patient-list");
        }
      } catch (error) {
        console.error("Error logging in:", error); // 에러 메시지를 추가하여 문제를 디버그
        if (error.response && error.response.status === 401) {
          alert("비밀번호를 확인해주세요");
        }
        if (
          error.response &&
          error.response.data.detail === "Doctor not found"
        ) {
          alert("존재하지 않는 이메일입니다.");
        }
      }
    } else if (location.pathname === "/signup") {
      setComplete(false);
      setReset(false);
      setRegistered(false);
    }
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      navigate("/patient-list");
    }
    // ... 기존 useEffect 코드 ...
  }, [navigate]);

  const handleId = (e) => {
    setId(e.target.value);
    setLoginFormData({ ...loginFormData, email: e.target.value });
  };

  const handlePswd = (e) => {
    setPassword(e.target.value);
    setLoginFormData({ ...loginFormData, password: e.target.value });
  };

  const handleChangePswd = (e) => {
    setChangePswd(e.target.value);
  };

  const mailformat =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const { name, email, hospital_name, license_number, phone_number, code } =
    inputValues;

  const signUpRules = {
    name: name.length >= 2,
    email: email.match(mailformat),
    password: inputValues.password?.length >= 8,
    hospital_name: hospital_name?.length > 2,
    license_number: license_number?.length > 2,
    phone_number: phone_number?.length === 13,
    code: code?.length === 6,
  };

  const formatPhoneNumber = (value) => {
    const numbersOnly = value.replace(/\D/g, "");
    if (numbersOnly.length <= 3) return numbersOnly;
    if (numbersOnly.length <= 7)
      return `${numbersOnly.slice(0, 3)}-${numbersOnly.slice(3)}`;
    return `${numbersOnly.slice(0, 3)}-${numbersOnly.slice(
      3,
      7
    )}-${numbersOnly.slice(7, 11)}`;
  };

  const handleInput = (e) => {
    const { id, value } = e.target;

    setInputValues((prevValues) => {
      const updatedValue =
        id === "phone_number" ? formatPhoneNumber(value) : value;
      return { ...prevValues, [id]: updatedValue };
    });
  };

  // console.log(inputValues.phone_number);

  useEffect(() => {
    setComplete(false);
    setReset(false);
    setRegistered(false);
    setPassword("");
    setChangePswd("");
  }, [location.pathname]);
  console.log(inputValues);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && isAllInputValid()) {
      // 이벤트 객체를 새로 생성해 target.value를 "CONTINUE"로 설정한 후 handleSubmit 호출
      const submitEvent = {
        ...e,
        target: {
          ...e.target,
          value: "CONTINUE",
        },
      };
      handleSubmit(submitEvent);
    }
  };

  const handleVerifyCode = async () => {
    try {
      const cleanedPhoneNumber = phone_number.replace(/-/g, "");
      const response = await axios.post(
        `${process.env.REACT_APP_APNOTRACK_URL}/v1/hub/auth/doctor/verify-code`,
        {
          phone_number: cleanedPhoneNumber,
          code,
        }
      );

      if (response.data.detail === "Verification code is valid") {
        navigate("/signup");
      }
    } catch (error) {
      console.error(error);
      setVerifiedButtonTitle("TRY AGAIN");
      alert("Please enter a valid code");
    }
  };

  return (
    <FrontPage
      title={
        reset
          ? "PASSWORD RESET"
          : location.pathname === "/signup" || location.pathname === "/verify"
          ? registered
            ? ""
            : "Sign UP"
          : "Login"
      }
      note={
        reset
          ? "If you log in with a temporary password, you are required to change your password to protect your personal information."
          : location.pathname === "/signup"
          ? registered
            ? ""
            : "Please create an account"
          : location.pathname === "/verify"
          ? "Please verify your phone number"
          : "Log in to your account."
      }
      btnDisabled={!isAllInputValid()}
      handleClick={handleSubmit}
      reset={reset}
      complete={complete}
      registered={registered}
      correct={password === changePswd}
      pathName={location.pathname}
      handleVerifyCode={handleVerifyCode}
      verifiedButtonTitle={verifiedButtonTitle}
    >
      <div className="flex flex-col gap-4 py-6 w-full">
        {reset && !complete && (
          <>
            <InputDefault
              id={password}
              value={password || ""}
              type="password"
              placeholder="특수문자/대,소문자/숫자 포함 8자리 이상"
              handleChange={handlePswd}
              success={isAllInputValid()}
            />
            <InputDefault
              id={password}
              value={changePswd || ""}
              placeholder="Confirm New Password"
              handleChange={handleChangePswd}
              success={password === changePswd && changePswd !== ""}
            />
          </>
        )}
        {complete && (
          <div>
            <span className="text-center text-[#929292]">
              The Password Change
              <br /> Has Been Completed.
            </span>
          </div>
        )}
        {!reset &&
          location.pathname !== "/signup" &&
          location.pathname !== "/verify" && (
            <>
              <InputDefault
                id={id}
                value={id || ""}
                placeholder="Email"
                handleChange={handleId}
                reset={reset}
                errorMsg={idValidationMsg}
                success={!idValidationMsg}
                handleKeyPress={handleKeyPress}
              />
              <InputDefault
                id={password || ""}
                value={password || ""}
                placeholder="password"
                handleChange={handlePswd}
                type="password"
                // reset={reset}
                errorMsg={passwordValidationMsg}
                success={!passwordValidationMsg}
                handleKeyPress={handleKeyPress}
              />
            </>
          )}
        {/* <div className='flex gap-3'>
          <InputDate placeholder='From...' handleChange={setStartDate} value={startDate} errorMsg={startDateValidationMsg} />
          <InputDate placeholder='To...' handleChange={setEndDate} value={endDate} errorMsg={endDateValidationMsg} />
        </div> */}
        {/* {!reset && location.pathname !== "/signup" && (
          <p
            className="text-end text-dark-blue font-medium cursor-pointer"
            onClick={resetPswd}
          >
            RESET PASSWORD
          </p>
        )} */}
        {location.pathname === "/signup" && !registered && (
          <>
            <SignUp
              handleInput={handleInput}
              name={inputValues.name}
              phoneNumber={inputValues.phone_number}
              password={inputValues.password}
              email={inputValues.email}
              hospital={inputValues.hospital_name}
              LicenseNumber={inputValues.license_number}
              signUpRules={signUpRules}
            />
          </>
        )}
        {location.pathname === "/verify" && !registered && (
          <Verify
            handleInput={handleInput}
            name={inputValues.name}
            phoneNumber={inputValues.phone_number}
            signUpRules={signUpRules}
            code={inputValues.code}
          />
        )}

        {registered && (
          // 회원가입 플로우에 따라서 문구를 변경해야 할수도 있음
          <div className="flex flex-col justify-center items-center gap-12">
            <span className="text-center font-bold uppercase text-gray-dark-2 text-2xl">
              Your registration is complete.
            </span>

            <ButtonDefault
              type="submit"
              value="LOG IN"
              size="small"
              width="medium"
              onlyBorder={true}
              handleClick={handleSubmit}
            />
          </div>
        )}
      </div>
    </FrontPage>
  );
}

export default Account;
