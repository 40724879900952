import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import PageNotFound from "./pages/PageNotFound";
import Account from "./pages/Account";
import SleepRoutineData from "./pages/SleepRoutineData";
import PatientList from "./pages/PatientList";
import DoctorList from "./pages/Admin/DoctorList";
import GA4React from "ga-4-react";
import AdminLogin from "./pages/Admin/Login";
const TRACKING_ID = "G-MH4GN5PGW7";

function App() {
  // ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    const ga4react = new GA4React(TRACKING_ID);

    ga4react.initialize().catch((err) => console.error(err));
    // ReactGA.pageview(window.location.pathname + window.location.search);
    window.scrollTo(0, 0);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Account />} />
        <Route path="/signup" element={<Account />} />
        <Route path="/verify" element={<Account />} />
        <Route path="/sleep-summary" element={<SleepRoutineData />} />
        <Route path="/patient-list" element={<PatientList />} />
        <Route path="/admin/doctor-list" element={<DoctorList />} />
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/admin/signup" element={<AdminLogin />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
