import { useEffect, useState } from "react";
import { Calendar } from "react-date-range";
import CalendarLightIcon from "../../../assets/calendar-icon-dark.svg";
import axios from "axios";
import BaseModal from "../BaseModal";
import ModalTitle from "../ModalTitle";
import ButtonDefault from "../../ButtonDefault";
import { formatDateDash } from "../../../lib/date";
import { ko } from "date-fns/locale";
import "react-date-range/dist/styles.css"; // 기본 스타일
import "react-date-range/dist/theme/default.css";
import "./customStyle.css";

// import { ko } from "date-fns";

function RegisterPatientModal({
  setList,
  handleCloseModal,
  filteredList,
  setFilteredList,
  survey,
  setSurvey,
}) {
  const accessToken = localStorage.getItem("access_token");

  const [formData, setFormData] = useState({
    name: "",
    birth_date: "",
    gender: "",
    tonsil_size: "",
  });
  // const [isValidDate, setIsValidDate] = useState(true);
  const [isNotEmpty, setIsNotEmpty] = useState({
    name: undefined,
    birth_date: undefined,
    gender: undefined,
    tonsil_size: undefined,
  });

  const [showCalendar, setShowCalendar] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    setIsNotEmpty({
      ...isNotEmpty,
      [name]: value ? true : false,
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectDate = (date) => {
    const formattedDate = formatDateDash(date);
    setFormData({
      ...formData,
      birth_date: formattedDate,
    });
    setShowCalendar(false);
  };

  const validateForm = () => {
    const { name, birth_date, gender, tonsil_size } = formData;
    const isNameValid = name?.trim() !== "" && name.length <= 20;
    const isBirthValid = birth_date !== "";
    const isGenderValid = gender?.trim() !== "";
    const isNeckCircumValid = tonsil_size?.trim() !== "";

    return isNameValid && isBirthValid && isGenderValid && isNeckCircumValid;
  };

  const addPatientList = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APNOTRACK_URL}/v1/hub/doctors/me/patients`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const updatedResponse = await axios.get(
        `${process.env.REACT_APP_APNOTRACK_URL}/v1/hub/doctors/me/patients`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setList(updatedResponse.data.result);
      handleCloseModal();
      setFilteredList(updatedResponse.data.result);

      console.log("Data updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  // const renderTextWithNewLines = (text) => {
  //   return text.split("\n").map((str, index) => (
  //     <span key={index}>
  //       {str}
  //       <br />
  //     </span>
  //   ));
  // };

  const handleOverlayClick = () => {
    setShowCalendar(false);
  };

  return (
    <BaseModal handleCloseModal={handleCloseModal}>
      <div
        className={`flex justify-between items-baseline gap-4 px-20 pt-8 pb-6`}
      >
        <ModalTitle title="환자 등록" note="" />
        <span
          className="flex justify-center items-center font-bold text-[#1C265F] cursor-pointer"
          onClick={handleCloseModal}
        >
          CLOSE
        </span>
      </div>

      <section className="flex flex-col gap-8 px-20 items-center">
        <div className="w-full">
          <div className="mb-4">
            <input
              type="text"
              name="name"
              placeholder="이름"
              autoComplete="off"
              value={formData.name}
              onChange={handleChange}
              className={`w-full px-4 py-3 border-2 border-gray-light  ${
                formData.name.length > 20 || formData.name.length === 0
                  ? "focus:outline-red "
                  : ""
              }`}
            />
          </div>
          <div className="mb-4">
            <div
              className="flex items-center border-2 border-gray-light px-4 py-3 w-full cursor-pointer "
              onClick={() => setShowCalendar(!showCalendar)}
            >
              <input
                type="text"
                value={
                  formData.birth_date ? formatDateDash(formData.birth_date) : ""
                }
                className={`w-full cursor-pointer`}
                placeholder="생년월일"
                readOnly
              />
              <img src={CalendarLightIcon} alt="none" className="w-[5%]" />
            </div>
            {showCalendar && (
              <div>
                <div
                  className="fixed inset-0 bg-gray-500 bg-opacity-50 z-10"
                  onClick={handleOverlayClick}
                ></div>

                <div className="absolute top-1/3 left-1/2 transform -translate-x-1/2 mt-2 z-40 w-[40%]">
                  <div className="custom-date-range">
                    <Calendar
                      direction="horizontal"
                      onChange={handleSelectDate}
                      date={
                        formData.birth_date
                          ? new Date(formData.birth_date)
                          : new Date()
                      }
                      locale={ko}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mb-4 w-full px-4 py-3 border-2 border-gray-light">
            <label>
              <select
                onChange={handleChange}
                defaultValue={formData.gender || "default"}
                className="w-full"
                name="gender"
                id="gender"
              >
                <option value="default" disabled>
                  성별
                </option>
                <option value="MALE">남자</option>
                <option value="FEMALE">여자</option>
              </select>
            </label>
          </div>
          <div className="mb-4 w-full px-4 py-3 border-2 border-gray-light">
            <label>
              <select
                onChange={handleChange}
                defaultValue={formData.tonsil_size || "default"}
                className="w-full"
                name="tonsil_size"
              >
                <option className="text-gray-light" value="default" disabled>
                  편도 크기 검사
                </option>
                <option value={1}>Grade 1</option>
                <option value={2}>Grade 2</option>
                <option value={3}>Grade 3</option>
                <option value={4}>Grade 4</option>
              </select>
            </label>
          </div>

          <section className="flex justify-center gap-4 px-20 pt-8 pb-6 text-center">
            <ButtonDefault
              value="RESISTER"
              disabled={!validateForm()}
              handleClick={addPatientList}
            />
          </section>
        </div>
      </section>
    </BaseModal>
  );
}

export default RegisterPatientModal;
