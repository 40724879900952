import { useEffect } from "react";
import loadingIcon from "../../assets/yellow_loading_elipse.png";
import ButtonDefault from "../ButtonDefault";

function Loading({ height, useLoadingText, ...props }) {
  useEffect(() => {
    // console.log("This is the res")
    // console.log(props.res)
  }, [props.res]);

  const containView = (data) => {
    return (
      <div
        className={`flex flex-col items-center justify-center w-full ${
          height === "small" ? "h-fit" : "h-[73vh]"
        }`}
      >
        {data}
      </div>
    );
  };

  return (
    <>
      {props.isLoading
        ? containView(
            <div className="flex flex-row w-full items-center justify-center h-[50vh]">
              {useLoadingText ? (
                <p>Loading...</p>
              ) : (
                <img
                  src={loadingIcon}
                  className="z-20 h-20 w-20 animate-spin"
                  alt="none"
                />
              )}
            </div>
          )
        : props.isLoaded
        ? props.error
          ? containView(
              <div className="flex flex-col gap-4 items-center justify-center w-full">
                <p className="text-xl text-center font-semibold w-full text-red">
                  {props.error}
                </p>
                <ButtonDefault
                  type="button"
                  width="small"
                  value="Try again"
                  handleClick={() => props.tryAgain()}
                />
              </div>
            )
          : props.msg
          ? containView(
              <p className="text-xl text-center font-semibold w-full">
                {props.msg}
              </p>
            )
          : props.res
          ? props.output
          : containView(
              <div className="flex flex-col gap-4 items-center justify-center w-full">
                <p className="text-xl text-center font-semibold w-full text-red">
                  Internal server error
                </p>
                <ButtonDefault
                  type="button"
                  width="small"
                  value="Try again"
                  handleClick={() => props.tryAgain()}
                />
              </div>
            )
        : containView(
            <p className="text-xl text-center font-semibold w-full">
              Unknown error has occured
            </p>
          )}
    </>
  );
}

export default Loading;
