
function ModalTitle({ title, note, ...props }) {
    return (
        <div className='flex flex-col gap-4'>
            <p className='text-3xl font-semibold uppercase'>{title}</p>
            <p className='text-lg text-justify'>{note}</p>
        </div>
    );
}

export default ModalTitle;
