function BaseModal({ handleCloseModal, ...props }) {
  return (
    <div
      className={`fixed w-full h-full  z-40 flex flex-row items-center justify-center top-0 left-0 bg-modal`}
    >
      <div
        className="absolute top-0 left-0 w-full h-full"
        onClick={handleCloseModal}
      ></div>
      <div className="flex flex-col bg-white w-1/2 xl:w-2/5 2xl:w-1/4 h-fit z-50  shadow-default">
        {props.children}
      </div>
    </div>
  );
}

export default BaseModal;
