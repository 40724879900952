import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import Icon from "../Icon";
import EditIcon from "../../assets/edit-icon.svg";
import InfoIcon from "../../assets/info-icon.svg";

function InputDefault({
  id,
  value,
  placeholder,
  type,
  disabled,
  success,
  hidePasswordShow,
  width,
  bg,
  handleChange,
  handleIdChange,
  handlePswdChange,
  handleKeyPress,
  showEditIcon,
  showInfoIcon,
  errorMsg,
  password,
  reset,
  ...props
}) {
  const [inpType, setInpType] = useState();
  const [showTip, setShowTip] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [showValidationMsg, setShowValidationMsg] = useState(false);
  const handlePasswordVisibilityChange = () => {
    if (isVisible) {
      setInpType("password");
    } else {
      setInpType("text");
    }

    setIsVisible(!isVisible);
  };

  const isInvalid = () => {
    return showValidationMsg && errorMsg;
  };

  useEffect(() => {
    setInpType(type);
  }, []);

  return (
    <div
      className={`flex flex-row justify-between items-center gap-4 px-4 py-3 border-2  ${
        bg ?? "bg-transparent"
      } ${
        success
          ? "border-light-green"
          : isInvalid()
          ? "border-red"
          : "border-gray-light"
      } ${width ?? ""}`}
    >
      <input
        id={id}
        type={inpType ?? "text"}
        className={`w-full  text-base placeholder:text-gray-light-3 outline-none 
        ${value === undefined || value === "" ? "" : "bg-[#e9f0fd]"}`}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        onBlur={() => setShowValidationMsg(true)}
        onKeyUp={handleKeyPress}
      />
      {/* <span className='flex flex-row items-center px-1' onClick={handlePasswordVisibilityChange}> */}
      {type === "password" && !hidePasswordShow ? (
        <span
          className="cursor-pointer flex flex-row items-center px-1"
          onClick={handlePasswordVisibilityChange}
        >
          {isVisible ? <Icon icon={faEye} /> : <Icon icon={faEyeSlash} />}
        </span>
      ) : showEditIcon ? (
        <span className="flex flex-row items-center px-1">
          <img src={EditIcon} className="w-4 h-4" alt="none" />
        </span>
      ) : showInfoIcon ? (
        <div
          className="relative"
          onMouseEnter={() => setShowTip(true)}
          onMouseLeave={() => setShowTip(false)}
        >
          <img src={InfoIcon} className="w-5 h-5" alt="none" />

          {/* <p className='py-2 px-3 text-white text-xs select-none' style={{ backgroundColor: v.color }}>{ind}</p> */}
          {showTip ? (
            <div>
              <p className="tip bg-gray-light absolute right-0 min-w-32 w-80 shadow-default top-9 py-1 px-2 text-sm z-50">
                Where to get the UUID? UUID is found in patient's Slee app under
                "Email Us" section.
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      {/* </span> */}
    </div>
  );
}

export default InputDefault;
