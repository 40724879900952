import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { formatDateMD } from "../lib/date";

const ERR_MSGS = {
  0: "Network error. Check your connection",
  500: "Internal server error",
  400: "Invalid input",
  401: "User not signed in yet",
  403: "Access to page not allowed",
  404: "Requested resource not found",
};

const useGetUserSleepData = (astId, startDate, endDate) => {
  const [averageStats, setAverageStats] = useState({
    result: {
      average_stats: {
        sleep_latency: 0,
        time_in_bed: 0,
        time_in_sleep: 0,
        time_in_wake: 0,
        sleep_efficiency: 0,
      },
      slept_sessions: [],
    },
    detail: "",
  });

  const [error, setError] = useState(null);
  const [isLoaded, setisLoaded] = useState(false);
  const [msg, setMsg] = useState("");
  const [arrSessionId, setArrSessionId] = useState([]);
  const [singleSessionList, setSingleSessionList] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [ahiData, setAhiData] = useState([]);
  const [isSession, setIsSession] = useState(false); // registered 상태 추가

  const apiKey = process.env.REACT_APP_ASLEEP_API_KEY;
  let unstablePatternCount = 0;
  const unstablePatterns = [
    "MODERATELY_UNSTABLE_BREATH",
    "SEVERELY_UNSTABLE_BREATH",
  ];

  const getUserAverageStats = useCallback(async () => {
    if (!astId || !startDate || !endDate) {
      return;
    } else if (astId) {
      setisLoading(true);
      try {
        const response = await axios.get(
          `/api/data/v1/users/${astId}/average-stats?start_date=${startDate}&end_date=${endDate}`,
          {
            headers: {
              "x-api-key": apiKey,
              timezone: "Asia/Seoul",
            },
          }
        );

        const neverSleptSessionIds =
          response.data.result?.never_slept_sessions?.map(
            (session) => session.id
          ) || [];
        const sleptSessionIds =
          response.data.result?.slept_sessions?.map((session) => session.id) ||
          [];

        setArrSessionId([...sleptSessionIds, ...neverSleptSessionIds]);
        setIsSession([...sleptSessionIds, ...neverSleptSessionIds].length > 0); // sessionId 길이에 따라 registered 설정

        setAverageStats(response.data);

        if (response.data?.message) {
          setMsg(response.data?.message);
        }
      } catch (error) {
        console.log(error);
        if (error.response?.data?.message) {
          setError(error.response.data.message);
        } else {
          let code = error.response?.status;
          let msg = ERR_MSGS[code] ?? "Unknown error has occured";

          setError(msg);
        }
      }
    }
  }, [astId, startDate, endDate, apiKey]);

  if (
    unstablePatterns.includes(
      singleSessionList.map((session) => session.result.stat.breathing_pattern)
    )
  ) {
    unstablePatternCount += 1;
  }

  const getSingleSession = useCallback(async () => {
    // console.log(unstablePatternCount);

    // console.log(arrSessionId);

    if (arrSessionId) {
      try {
        const singleSessionPromise = arrSessionId.map((sessionId) =>
          axios.get(`/api/data/v3/sessions/${sessionId}`, {
            headers: {
              "x-api-key": apiKey,
              "x-user-id": astId,
              timezone: "Asia/Seoul",
            },
          })
        );
        const singleSessionResponse = await Promise.all(singleSessionPromise);
        const singleSession = singleSessionResponse.map((res) => res.data);

        setSingleSessionList(singleSession.reverse());
      } catch (error) {
      } finally {
        setisLoaded(true);
        setisLoading(false);
      }
    }
  }, [arrSessionId, apiKey, astId]);

  useEffect(() => {
    setAhiData([]); // 날짜가 변경될 때 ahiData 초기화
  }, [startDate, endDate]);

  useEffect(() => {
    getUserAverageStats();
  }, [getUserAverageStats]);
  useEffect(() => {
    getSingleSession();
  }, [getSingleSession]);

  useEffect(() => {
    setAhiData(
      singleSessionList
        .filter((session) => session.result.peculiarities.length === 0) // peculiarities가 빈 배열인 세션만 필터링
        .map((session) => {
          return {
            ahi: session.result.stat.breathing_index,
            day: formatDateMD(session.result.session.end_time),
          };
        })
    );
  }, [singleSessionList]);

  return {
    averageStats,
    singleSessionList,
    ahiData,
    error,
    isLoading,
    isLoaded,
    msg,
    unstablePatternCount,
    isSession,
  };
};

export default useGetUserSleepData;
