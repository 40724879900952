import ButtonDefault from "../ButtonDefault";
import InputDefault from "../InputDefault";

function SignUp({
  handleInput,
  name,
  email,
  password,
  hospital,
  phoneNumber,
  LicenseNumber,
  signUpRules,
  pathName,
}) {
  return (
    <>
      {pathName === "/admin/signup" ? (
        <>
          <InputDefault
            id={"name"}
            value={name}
            placeholder="Name"
            handleChange={handleInput}
            success={signUpRules.name}
          />
          <InputDefault
            id="email"
            value={email}
            placeholder="Email(ID)"
            handleChange={handleInput}
            success={signUpRules.email}
          />
          <InputDefault
            id="password"
            value={password}
            placeholder="Password"
            handleChange={handleInput}
            success={signUpRules.password}
          />
        </>
      ) : (
        <>
          <div className="flex justify-between gap-1">
            <InputDefault
              id={"name"}
              value={name}
              placeholder="Name"
              handleChange={handleInput}
              width={"w-full"}
              success={signUpRules.name}
              disabled={true}
            />
          </div>

          <InputDefault
            id="phone_number"
            type="tel"
            value={phoneNumber}
            placeholder="Phone Number"
            handleChange={handleInput}
            success={signUpRules.phone_number}
            disabled={true}
          />

          <InputDefault
            id="email"
            value={email}
            placeholder="Email(ID)"
            handleChange={handleInput}
            success={signUpRules.email}
          />
          <InputDefault
            id="password"
            value={password}
            type="password"
            placeholder="Password"
            handleChange={handleInput}
            success={signUpRules.password}
          />
          <InputDefault
            id={"hospital_name"}
            value={hospital}
            placeholder="Hospital name"
            handleChange={handleInput}
            type="string"
            success={signUpRules.hospital_name}
          />
          <InputDefault
            id={"license_number"}
            value={LicenseNumber}
            placeholder="Physician's License Number"
            handleChange={handleInput}
            type="number"
            success={signUpRules.license_number}
          />
        </>
      )}
    </>
  );
}

export default SignUp;
