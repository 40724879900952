import { faAngleDown, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Icon from "../../components/Icon";
import ZoomBlueIcon from "../../assets/zoom-blue-icon.svg";
import ZoomGrayIcon from "../../assets/zoom-gray-icon.svg";
import { useEffect, useRef, useState } from "react";
import Loading from "../../components/Loading";
import { formatDateDot } from "../../lib/date";
import SleeAppLegend from "./slee_app_legend";
import ButtonDefault from "../../components/ButtonDefault";
import { useLocation, useNavigate } from "react-router-dom";
import CONSTANTS from "../../lib/constant";
import Summary from "./summary";
import DailyGraph from "./dailyGraph";
import { getStagesAndHours } from "./lib";
import IssueCodeModal from "../../components/modals/IssueCodeModal";
import EditInfoModal from "../../components/modals/EditInfoModal";
import useGetUserSleepData from "../../hooks/useGetPatientSleepData";
import CalendarLightIcon from "../../assets/calendar-icon-dark.svg";

function SleepRoutineData({ value, name, handleClose, disabled, ...props }) {
  const accessToken = localStorage.getItem("access_token");
  const navigate = useNavigate();
  const location = useLocation();
  const [prescriptions, setPrescriptions] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [info, setInfo] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dataModified, setDataModified] = useState([]);
  const [isZoomed, setIsZoomed] = useState(false);
  const [detailData, setDetailData] = useState([]);
  const [expire, setExpire] = useState(false);
  const astId = detailData.ast_id;

  const id = new URLSearchParams(location.search).get("id");

  const {
    averageStats,
    singleSessionList,
    ahiData,
    unstablePatternCount,
    error,
    isLoading,
    isLoaded,
    msg,
    isSession,
  } = useGetUserSleepData(astId, startDate, endDate);

  console.log(averageStats, ahiData);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APNOTRACK_URL}/v1/hub/doctors/me/patients/${id}/prescriptions`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        // end_date를 기준으로 정렬
        const sortedPrescriptions = response.data.result.sort(
          (a, b) => new Date(a.end_date) - new Date(b.end_date)
        );
        setPrescriptions(sortedPrescriptions.reverse()); // 내림차순으로 정렬
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [accessToken, id]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APNOTRACK_URL}/v1/hub/doctors/me/patients/${id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        // end_date를 기준으로 정렬
        setDetailData(response.data.result);
      } catch (error) {
        console.log(error.message);
        handleBack();
      }
    }
    fetchData();
  }, [accessToken, id]);

  useEffect(() => {
    if (prescriptions.length > 0) {
      setSelectedPrescription(prescriptions[0]);
      setStartDate(prescriptions[0].start_date);
      setEndDate(prescriptions[0].end_date);
    }
  }, [prescriptions]);

  const componentRef = useRef();

  const handleBack = () => {
    navigate("/patient-list");
  };

  const toggleZoomAll = () => {
    let dtMdf = [...dataModified];
    for (let i = 0; i < dataModified.length; i++) {
      let row = dataModified[i];
      if (!row?.disabled) {
        let r = { ...row };
        r.zoom = isZoomed;
        r = getStagesAndHours(r);
        dtMdf[i] = r;
      }
    }
    setDataModified(dtMdf);
  };

  useEffect(() => {
    toggleZoomAll();
  }, [isZoomed]);

  const openModal = (e) => {
    e.preventDefault();
    const value = e.target.value;
    if (value === "환자 정보") {
      setInfo(true);
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setExpire(false);
    setInfo(false);
  };

  useEffect(() => {
    if (id === null) {
      handleBack();
    }
  });
  const registered = detailData.is_registered;

  const birthDay = new Date(detailData?.birth_date);
  const year = String(birthDay?.getFullYear());
  const month = String(birthDay?.getMonth() + 1).padStart(2, "0");
  const day = String(birthDay?.getDate()).padStart(2, "0");
  const dateOfBirth = `${year}-${month}-${day}`;

  let Gender;
  if (detailData?.gender === "MALE") {
    Gender = "남";
  } else if (detailData?.gender === "FEMALE") {
    Gender = "여";
  } else {
    Gender = "기타";
  }

  const handleCheckCode = async () => {
    setExpire(true);
    setShowModal(true);
  };

  // **새롭게 추가된 상태 변수 및 핸들러**
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedPrescription, setSelectedPrescription] = useState(null);
  const dropdownRef = useRef();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelectPrescription = (prescription) => {
    setSelectedPrescription(prescription);
    setStartDate(prescription.start_date);
    setEndDate(prescription.end_date);
    setIsDropdownOpen(false);
  };

  // **드롭다운 외부 클릭 시 닫기**
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="h-[100vh] col-span-full flex flex-col w-full gap-6 p-8 bg-cream">
      {showModal && expire && (
        <IssueCodeModal
          handleCloseModal={handleCloseModal}
          id={detailData.id}
          setDetailData={setDetailData}
          detailData={detailData}
          setPrescriptions={setPrescriptions}
        />
      )}

      {showModal && info && (
        <EditInfoModal
          handleCloseModal={handleCloseModal}
          birth_date={dateOfBirth}
          setDetailData={setDetailData}
          detailData={detailData}
        />
      )}

      <div className="flex justify-between items-center w-full">
        <button
          className="border border-gray-light absolute bottom-4 right-4 rounded-lg bg-white shadow-default p-2 z-10"
          onClick={() => setIsZoomed(!isZoomed)}
        >
          <img
            src={isZoomed ? ZoomGrayIcon : ZoomBlueIcon}
            className="w-10 h-10"
            alt="Zoom Icon"
          />
        </button>
        <div className="flex flex-col gap-8">
          <div className="flex gap-6">
            <button onClick={handleBack}>
              <Icon icon={faAngleLeft} size="2xl" />
            </button>
            <div className="text-3xl cursor-pointer" onClick={handleBack}>
              뒤로
            </div>
          </div>
          {detailData?.length === 0 ? (
            <div>Loading...</div>
          ) : (
            <div className="text-4xl pl-8 ">
              {detailData?.name}({year}.{month}.{day} / {Gender} /{" "}
              <span className="text-black ">{detailData?.patient_code}</span> )
            </div>
          )}
        </div>

        <div className="flex flex-col-reverse gap-6 h-fit">
          <div className="flex justify-end gap-6">
            <SleeAppLegend title="Sleep stages" data={CONSTANTS.SLEEP_STAGES} />
            <SleeAppLegend
              title="APNEA / HYPOPNEA EVENTS"
              data={CONSTANTS.APNEA_STAGES}
            />
          </div>

          <div className="flex gap-6 h-full justify-end ">
            {/* 사용자 정의 드롭다운 */}
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={toggleDropdown}
                className="w-50 h-full flex gap-2 justify-between items-center bg-white border border-gray-light p-2 rounded"
              >
                <img src={CalendarLightIcon} alt="none" className="w-[7%]" />
                {selectedPrescription
                  ? `${formatDateDot(
                      selectedPrescription.start_date
                    )} - ${formatDateDot(selectedPrescription.end_date)}`
                  : "처방 기간 선택"}
                <span
                  className={`ml-2 transition-transform ${
                    isDropdownOpen ? "rotate-180" : "rotate-0"
                  }`}
                >
                  <Icon icon={faAngleDown} size="2xl" />
                </span>
              </button>

              {isDropdownOpen && (
                <ul className="absolute mt-2 w-full bg-white border border-gray-light rounded shadow-lg max-h-60 overflow-y-auto z-10">
                  {prescriptions.map((prescription) => {
                    return (
                      <li
                        key={prescription.id}
                        onClick={() => handleSelectPrescription(prescription)}
                        className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                          selectedPrescription?.id === prescription.id
                            ? "bg-slate-200"
                            : ""
                        }`}
                      >
                        {formatDateDot(prescription.start_date)} -{" "}
                        {formatDateDot(prescription.end_date)}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>

            <ButtonDefault
              value="처방 기간 설정"
              handleClick={handleCheckCode}
              size={"small"}
              width={"small"}
            />
            <ButtonDefault
              value="환자 정보"
              handleClick={openModal}
              hollow={true}
              size={"small"}
              width={`small`}
            />
          </div>
        </div>
      </div>
      <span
        className={`${
          registered && isSession
            ? "hidden"
            : "font-bold font-20 text-black text-xl fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
        }`}
      >
        환자코드 등록이 미완료 되었거나, 조회할 수면 데이터가 없습니다.
      </span>
      <div
        className={`h-[84vh] w-full bg-white pl-8 pr-2 py-10 scroll-m-2 shadow-default flex flex-col gap-3 ${
          registered && isSession
            ? "overflow-y-scroll"
            : " opacity-30  overflow-y-hidden"
        }`}
      >
        <Loading
          isLoading={isLoading}
          isLoaded={isLoaded}
          error={error}
          msg={msg}
          res={"resG"}
          output={
            <div
              ref={componentRef}
              id="slee-app-data"
              className={`flex flex-col gap-4 px-3 `}
            >
              <Summary
                startDate={startDate}
                endDate={endDate}
                averageStats={averageStats?.result}
                summary={averageStats?.result?.average_stats}
                unstablePatternCount={unstablePatternCount}
                ahiData={ahiData}
              />

              {singleSessionList.length > 0 && (
                <DailyGraph
                  singleSessionList={singleSessionList}
                  isZoomed={isZoomed}
                />
              )}
            </div>
          }
        />
      </div>
    </div>
  );
}

export default SleepRoutineData;
