import Title from '../../components/Title';

function PageNotFound() {
  return (
    <div className="w-full h-screen bg-white flex flex-row justify-center items-center">
      <Title value='Page not found' size='big' />
    </div>
  );
}

export default PageNotFound;
