import { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import InputDefault from "../../../components/InputDefault";
import ButtonDefault from "../../../components/ButtonDefault";
import useValidatedState from "../../../hooks/useValidatedState";
import FrontPage from "../../../components/FrontPage";
import axios from "axios";
import SignUp from "../../../components/SignUp";
function AdminLogin() {
  const navigate = useNavigate();
  const location = useLocation();
  // const [startDate, setStartDate] = useState(getDateBeforeNDays(14));
  //   const [endDate, setEndDate] = useState(getDateBeforeNDays(0));

  const [id, setId, idValidationMsg] = useValidatedState({
    required: true,
    fieldName: "email",
    type: "email",
  });
  const [password, setPassword, passwordValidationMsg] = useValidatedState({
    required: true,
    fieldName: "password",
    type: "password",
  });

  // const [startDate, setStartDate, startDateValidationMsg] = useValidatedState({
  //   required: false,
  //   fieldName: "start date",
  //   type: "date",
  //   defaultVal: getDateArray(getDateBeforeNDays(14)),
  // });
  // const [endDate, setEndDate, endDateValidationMsg] = useValidatedState({
  //   required: false,
  //   fieldName: "end date",
  //   type: "date",
  //   defaultVal: getDateArray(getDateBeforeNDays(0)),
  // });

  const [reset, setReset] = useState(false);
  const [complete, setComplete] = useState(false);
  const [signUp, setSignUp] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [changePswd, setChangePswd] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      navigate("/admin/doctor-list");
    }
  }, []);

  const handleSubmit = async (e) => {
    if (e.target.value === "SAVE") {
      setComplete(true);
    } else if (e.target.value === "LOG IN") {
      // PUT Method로 정보 수정하기.
      setComplete(false);
      setReset(false);
      setRegistered(false);
      setPassword("");
      navigate("/admin");
    } else if (e.target.value === "REGISTER") {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_APNOTRACK_URL}/v1/hub/auth/admin/signup`,

          inputValues
        );
        console.log("Data updated successfully:", response.data);

        setInputValues({
          name: "",
          email: "",
          password: "",
        });
        alert("회원가입이 완료되었습니다.");
        navigate("/admin");
      } catch (error) {
        console.error("Error updating data:", error.response.data.detail);

        if (error.response?.data?.detail === "Email already exists") {
          setInputValues({
            name,
            email: "",
            password,
          });
        }
        alert(error.response.data.detail);
      }
    } else if (e.target.value === "CONTINUE") {
      try {
        await axios
          .post(
            `${process.env.REACT_APP_APNOTRACK_URL}/v1/hub/auth/admin/login`,
            { email: id, password }
          )
          .then((data) => {
            if (data.data.result.access_token) {
              localStorage.setItem(
                "access_token",
                data.data.result.access_token
              );
              sessionStorage.setItem(
                "refresh_token",
                data.data.result.refresh_token
              );
              navigate("/admin/doctor-list");
            } else {
              alert("다시 시도해주세요.");
            }
          });
        // .then(navigate("/admin/doctor-list"));
      } catch (error) {
        console.error("Error logging in:", error); // 에러 메시지를 추가하여 문제를 디버그

        if (
          error.response &&
          error.response.data.detail === "Invalid email or password"
        ) {
          alert("Invalid email or password.");
        }
      }

      //POST url : https://virtserver.swaggerhub.com/asleepmockapi/Apnotrack/1.0.0/api/v2/doctor/signup
    }
  };

  // useEffect(() => {
  //   let urlParams = parseURL(location.search);
  //   console.log("Parsed url", urlParams);
  //   if (urlParams) {
  //     setStartDate(getDateArray(urlParams?.start_date));
  //     setEndDate(getDateArray(urlParams?.end_date));
  //     setId(urlParams?.uuid);
  //   }
  // }, []);

  const resetPswd = () => {
    setReset(true);
    setId("");
    setPassword("");
  };
  const handleId = (e) => {
    setId(e.target.value);
  };

  const handlePswd = (e) => {
    setPassword(e.target.value);
  };

  const handleChangePswd = (e) => {
    setChangePswd(e.target.value);
  };

  const [inputValues, setInputValues] = useState({
    name: "",
    email: "",
    password: "",
  });

  const mailformat =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const { name, email } = inputValues;

  // console.log(isAllInputValid());

  const signUpRules = {
    name: name.length >= 2,
    email: email.match(mailformat),
    password: inputValues.password.length >= 8,
  };

  const isAllInputValid = () => {
    let allFields = reset
      ? [passwordValidationMsg]
      : location.pathname === "/admin/signup"
      ? [
          signUpRules.name ? undefined : "",
          signUpRules.email ? undefined : "",
          signUpRules.password ? undefined : "",
        ]
      : [idValidationMsg, passwordValidationMsg];
    for (let i of allFields) {
      if (i !== undefined) {
        return false;
      }
    }

    return true;
  };

  const handleInput = (e) => {
    const { id, value } = e.target;

    setInputValues({ ...inputValues, [id]: value });
  };

  useEffect(() => {
    if (location.pathname === "/admin/signup") {
      setComplete(false);
      setReset(false);
      setRegistered(false);
    }
  }, [location.pathname]);

  // console.log(location.pathname);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && isAllInputValid()) {
      // 이벤트 객체를 새로 생성해 target.value를 "CONTINUE"로 설정한 후 handleSubmit 호출
      const submitEvent = {
        ...e,
        target: {
          ...e.target,
          value: "CONTINUE",
        },
      };
      handleSubmit(submitEvent);
    }
  };

  return (
    <FrontPage
      title={location.pathname === "/admin/signup" ? "Sign UP" : "Login"}
      note={
        location.pathname === "/admin/signup"
          ? "Please create an account"
          : "Log in to your account."
      }
      btnDisabled={!isAllInputValid()}
      handleClick={handleSubmit}
      reset={reset}
      complete={complete}
      registered={registered}
      correct={password === changePswd}
      pathName={location.pathname}
    >
      {location.pathname === "/admin" && (
        <div className="flex flex-col gap-4 py-6 w-full">
          <InputDefault
            id={id}
            value={id || ""}
            placeholder="Email"
            handleChange={handleId}
            reset={reset}
            errorMsg={idValidationMsg}
            success={!idValidationMsg}
            handleKeyPress={handleKeyPress}
          />
          <InputDefault
            id={password || ""}
            value={password || ""}
            placeholder="password"
            handleChange={handlePswd}
            type="password"
            // reset={reset}
            errorMsg={passwordValidationMsg}
            success={!passwordValidationMsg}
            handleKeyPress={handleKeyPress}
          />
        </div>
      )}
      {location.pathname === "/admin/signup" && (
        <>
          <SignUp
            handleInput={handleInput}
            // name={inputValues[name]}
            email={inputValues[email]}
            password={inputValues.password}
            signUpRules={signUpRules}
            pathName={location.pathname}
          />
        </>
      )}
    </FrontPage>
  );
}

export default AdminLogin;
