import { formatSeconds } from "../../lib/date";

import CONSTANTS from "../../lib/constant";
import SanitizedDiv from "../../components/SanitizedDiv";
import AHIChart from "../../components/AHIGraph";
import { differenceInDays } from "date-fns";

function Summary({
  note,
  noOfWeeks,
  noOfDays,
  summary,
  averageStats,
  unstablePatternCount,
  stackedData,
  isPrint,
  ahiData,
  startDate,
  endDate,
  ...props
}) {
  const calculateAverageByDay = (sessions) => {
    const weekDays = ["일", "월", "화", "수", "목", "금", "토"];

    // console.log(sessions);
    if (sessions) {
      // 요일별로 데이터를 그룹화
      const dayGroups = sessions.reduce((acc, session) => {
        const sessionEndTime = new Date(session.end_time);
        const dayOfWeek = weekDays[sessionEndTime.getDay()]; // 요일을 구함

        if (!acc[dayOfWeek]) {
          acc[dayOfWeek] = [];
        }
        acc[dayOfWeek].push(session);
        return acc;
      }, {});

      // 요일별로 값을 더하고 평균을 구함
      const averagesByDay = Object.keys(dayGroups).map((day) => {
        const sessionsForDay = dayGroups[day];
        const sumStats = sessionsForDay.reduce((acc, session) => {
          console.log(session);
          CONSTANTS.ASLEE_APP_TABLE_TITLES.forEach((title) => {
            const variable = title.variable;
            if (session[variable] !== undefined) {
              if (!acc[variable]) {
                acc[variable] = 0;
              }
              acc[variable] += session[variable];
            }
          });

          return acc;
        }, {});

        // 평균값 계산
        const avgStats = Object.keys(sumStats).reduce((acc, key) => {
          acc[key] = sumStats[key] / sessionsForDay.length;
          return acc;
        }, {});

        return {
          day,
          avgStats,
        };
      });

      // 요일 순서에 따라 정렬
      const sortedAveragesByDay = weekDays
        .map(
          (day) =>
            averagesByDay.find((a) => a.day === day) || { day, avgStats: {} }
        )
        .filter(Boolean); // 존재하는 요일만 필터링

      return sortedAveragesByDay;
    }

    // 세션이 없을 경우 기본값으로 모든 요일 반환
    return weekDays.map((day) => ({ day, avgStats: {} }));
  };

  const averageByDay = calculateAverageByDay(averageStats?.slept_sessions);

  // console.log(averageStats.slept_sessions);

  // useEffect(()=>{
  // },[])
  // console.log(summary);
  // let total_detected = 0;
  // {
  //   CONSTANTS.DAYS.map((val, ind) =>
  //     // console.log(summary.sleep_stages[val]?.breathing_pattern)
  //     {
  //       console.log(val, ind, summary.sleep_stages[val]?.breathing_pattern);
  //     }
  //   );
  // }

  const totalAHI = ahiData.reduce((sum, item) => sum + item.ahi, 0);

  const uniqueDays = new Set(ahiData.map((item) => item.day)); // 중복된 day 값을 제거
  const usageDays = uniqueDays.size; // 고유한 day의 개수

  return (
    <div
      className="flex flex-col px-3 mb-8 gap-10 w-[70%] text-black"
      id="summary"
    >
      <h2 className="text-4xl uppercase font-medium">Summary</h2>
      {isPrint ? (
        <>
          <h2 className="text-2xl uppercase font-medium">Note</h2>
          <SanitizedDiv text={note ?? "N/A"} />
        </>
      ) : (
        ""
      )}

      <div className="flex flex-col gap-5">
        <h2 className="text-2xl mb-2 uppercase font-medium">Compliance</h2>

        <table width="100%" className="table-fixed">
          <tbody>
            <tr>
              <td>Days of Prescription</td>
              <td>
                {startDate &&
                  endDate &&
                  differenceInDays(endDate, startDate) + 1}{" "}
                days
              </td>
            </tr>
            <tr>
              <td>Compliance Days</td>
              <td>
                {usageDays} {usageDays <= 1 ? "day" : "days"}
              </td>
            </tr>
            <tr>
              <td>Compliance Ratio</td>
              <td>
                {usageDays
                  ? (
                      (usageDays / (differenceInDays(endDate, startDate) + 1)) *
                      100
                    ).toFixed(1)
                  : 0}
                %
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="flex flex-col gap-5">
        <h2 className="text-2xl mb-2 uppercase font-medium">Sleep APNEA</h2>{" "}
        <table width="100%" className="table-fixed">
          <tbody>
            <tr>
              <td>Average Estimated AHI</td>
              <td>
                {ahiData.length > 0
                  ? `${(totalAHI / ahiData.length).toFixed(2)}/ h` || 0
                  : "N/A"}{" "}
              </td>
            </tr>
            <tr>
              <td>Days with AHI &ge; 15 Detected</td>
              <td>
                {ahiData?.length > 0 ? (
                  <>
                    {unstablePatternCount <= 1
                      ? `${unstablePatternCount} day`
                      : `${unstablePatternCount} days`}{" "}
                    /{" "}
                    {ahiData.length <= 1
                      ? `${ahiData.length} day`
                      : `${ahiData.length} days`}
                  </>
                ) : (
                  "N/A"
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {ahiData.length > 0 ? (
        <AHIChart ahiData={ahiData.reverse()} />
      ) : (
        <div className={`flex flex-col gap-5 w-[215mm] h-[500px] $`}>
          <h2 className="text-2xl uppercase font-medium text-black mt-[1.5rem]">
            ESTIMATED AHI
          </h2>

          <span className="flex bg-slate-100 py-1 h-full justify-center items-center opacity-60">
            Not Available.
          </span>
        </div>
      )}

      <div className="flex flex-col gap-5">
        <h2 className="text-2xl mb-2 uppercase font-medium">
          Average Sleep METRICS
        </h2>

        <table width="100%" className="table-fixed">
          <tbody>
            <tr>
              <td>Sleep Efficiency</td>
              <td>
                {summary?.sleep_efficiency
                  ? (summary?.sleep_efficiency * 100).toFixed(0)
                  : 0}
                %
              </td>
            </tr>
            <tr>
              <td>Total Sleep Time</td>
              <td>{formatSeconds(summary?.time_in_sleep || 0)}</td>
            </tr>
            <tr>
              <td>Sleep Onset Latency</td>
              <td>{formatSeconds(summary?.sleep_latency || 0)}</td>
            </tr>

            <tr>
              <td>Wake After Sleep Onset</td>
              <td>{formatSeconds(summary?.time_in_wake || 0)}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="flex flex-col gap-5">
        <h2 className="text-2xl uppercase font-medium">WEEKLY SLEEP METRICS</h2>
        <table width="100%" className="">
          <thead>
            <tr>
              <th className="w-max">Days</th>
              <th>Sleep Efficiency</th>
              <th>Total Sleep Time</th>
              <th>Sleep Onset Latency</th>
              <th>Wake After Sleep Onset</th>
              <th>N1 / N2</th>
              <th>N3</th>
              <th>REM</th>
            </tr>
          </thead>

          <tbody>
            {averageByDay?.map((a, ind) => {
              // console.log(a.avgStats);
              return (
                <tr key={ind}>
                  <td className="border border-gray-400 text-center">
                    {a.day}요일
                  </td>
                  <td className="border border-gray-400 text-center">
                    {a.avgStats.sleep_efficiency
                      ? `${(a.avgStats.sleep_efficiency * 100).toFixed(0)} %`
                      : "N/A"}
                  </td>
                  <td className="border border-gray-400 text-center">
                    {a.avgStats.time_in_sleep
                      ? formatSeconds(a.avgStats.time_in_sleep)
                      : "N/A"}
                  </td>
                  <td className="border border-gray-400 text-center">
                    {a.avgStats.sleep_latency
                      ? formatSeconds(a.avgStats.sleep_latency)
                      : "N/A"}
                  </td>

                  <td className="border border-gray-400 text-center">
                    {a.avgStats.time_in_wake
                      ? formatSeconds(a.avgStats.time_in_wake)
                      : "N/A"}
                  </td>

                  <td className="border border-gray-400 text-center">
                    {a.avgStats.light_ratio
                      ? `${Math.round(a.avgStats.light_ratio * 100)}%`
                      : "N/A"}
                  </td>
                  <td className="border border-gray-400 text-center">
                    {a.avgStats.deep_ratio
                      ? `${Math.round(a.avgStats.deep_ratio * 100)}%`
                      : "N/A"}
                  </td>
                  <td className="border border-gray-400 text-center">
                    {a.avgStats.rem_ratio
                      ? `${Math.round(a.avgStats.rem_ratio * 100)}%`
                      : "N/A"}
                  </td>
                </tr>
              );
            })}
            {/* {CONSTANTS.DAYS.map(()=>{})} */}
            {/* {CONSTANTS.DAYS.map((val, ind) =>
              summary.sleep_stages[val] ? (
                <tr key={ind}> */}
            {/* <td className="uppercase w-max max-w-[6rem]">
                    {summary.sleep_stages[val].day}
                  </td>
                  <td className="text-center">
                    {roundNo(
                      summary.sleep_stages[val].light,
                      summary.sleep_stages[val].count
                    )}
                    %
                  </td>
                  <td className="text-center">
                    {roundNo(
                      summary.sleep_stages[val].deep,
                      summary.sleep_stages[val].count
                    )}
                    %
                  </td>
                  <td className="text-center">
                    {roundNo(
                      summary.sleep_stages[val].rem,
                      summary.sleep_stages[val].count
                    )}
                    %
                  </td>
                  <td className="text-center">
                    {formatSeconds(
                      roundNo(
                        summary.sleep_stages[val].sleep_latency,
                        summary.sleep_stages[val].count
                      )
                    )}
                  </td>
                  <td className="text-center">
                    {roundNo(
                      summary.sleep_stages[val].sleep_efficiency,
                      summary.sleep_stages[val].count
                    )}
                    %
                  </td>
                  <td className="text-center">
                    {formatSeconds(
                      roundNo(
                        summary.sleep_stages[val].time_in_sleep,
                        summary.sleep_stages[val].count
                      )
                    )}
                  </td> */}
            {/* <td className='text-center'>{roundNo(summary.sleep_stages[val].light, val)}%</td> */}
            {/* <td className='text-center'>{roundNo(summary.sleep_stages[val].deep, val)}%</td> */}
            {/* <td className='text-center'>{roundNo(summary.sleep_stages[val].rem, val)}%</td> */}
            {/* </tr> */}
            {/* ) : (
                "" */}
            {/* ) */}
            {/* )} */}
          </tbody>
        </table>
      </div>

      {isPrint ? <div className="block h-[40rem]"></div> : ""}
    </div>
  );
}

export default Summary;
