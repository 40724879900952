import loadingIcon from "../../assets/yellow_loading_elipse.png";

function ButtonDefault({
  type,
  value,
  disabled,
  hollow,
  onlyBorder,
  handleClick,
  size,
  height,
  width,
  btnWidth,
  isLoading,
  ...props
}) {
  const getHeight = () => {
    if (size === "small") {
      return "py-3 text-lg";
    } else if (size === "big") {
      return "py-4 text-2xl";
    }

    return "py-4 text-xl";
  };

  return (
    <button
      type={type ?? "button"}
      value={value}
      className={`!disabled:cursor-pointer uppercase ${getHeight()} text-center ${
        width === "small" ? "px-4" : width === "medium" ? "px-8" : "px-24"
      } ${
        btnWidth ?? "w-fit"
      }    font-semibold disabled:border-none disabled:text-dark-blue disabled:bg-gray-light ${
        onlyBorder
          ? "bg-transparent text-dark-blue border-gray-light border-2"
          : hollow
          ? "bg-gray-light text-dark-blue border-none"
          : "bg-dark-blue text-white"
      }`}
      disabled={disabled || isLoading}
      onClick={handleClick}
    >
      {isLoading ? (
        <>
          <img src={loadingIcon} className="z-20 h-6 animate-spin" alt="none" />
        </>
      ) : (
        ""
      )}
      {value}
    </button>
  );
}

export default ButtonDefault;
