import { useState } from "react";
import axios from "axios";
import BaseModal from "../BaseModal";
import ModalTitle from "../ModalTitle";
import ButtonDefault from "../../ButtonDefault";
import InputDefault from "../../InputDefault";

function EditDoctorInfoModal({
  handleCloseModal,
  id,
  setFilteredList,
  name,
  hospital_name,
  email,
  license_number,
  fetchData,
}) {
  const accessToken = localStorage.getItem("access_token");
  const [formData, setFormData] = useState({
    name,
    hospital_name,
    email,
    license_number,
  });
  const [isNotEmpty, setIsNotEmpty] = useState({
    name,
    hospital_name,
    email,
    license_number,
  });

  const handleInput = (e) => {
    e.preventDefault();
    const { id, value } = e.target;

    setIsNotEmpty({
      ...isNotEmpty,
      [id]: value ? true : false,
    });

    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const validateForm = () => {
    const { name, hospital_name, email, license_number } = formData;
    const isNameValid = name?.trim() !== "";
    const isHospitalValid = hospital_name?.trim() !== "";
    const isMailValid = email?.trim() !== "";
    const isLicenseValid = license_number !== "";
    return isNameValid && isHospitalValid && isMailValid && isLicenseValid;
  };

  // const editDoctorInfo = async (e) => {
  //   e.preventDefault();
  //   try {
  //     await axios
  //       .put(`${process.env.REACT_APP_APNOTRACK_URL}/doctor/${id}`, formData, {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       })
  //       .then(fetchData);
  //   } catch (error) {
  //     console.error("Error updating data:", error);
  //   }
  // };

  return (
    <BaseModal handleCloseModal={handleCloseModal}>
      <div className="flex justify-between items-baseline gap-4 px-20 pt-8 pb-6">
        <ModalTitle title="EDIT DOCTOR INFO" note="" />
        <span
          className="flex justify-center items-center font-bold text-[#1C265F] cursor-pointer"
          onClick={handleCloseModal}
        >
          CLOSE
        </span>
      </div>
      <section className="flex flex-col gap-8 px-20 items-center">
        <div className="w-full flex flex-col gap-4">
          {/* <form onSubmit={handleSubmit}> */}
          <InputDefault
            id="name"
            value={formData.name}
            placeholder="Full Name..."
            handleChange={handleInput}
            // success={signUpRules.name}
          />
          <InputDefault
            id="email"
            value={formData.email}
            placeholder="Email(ID)"
            handleChange={handleInput}
            // success={signUpRules.email}
          />
          <InputDefault
            id={"hospital_name"}
            value={formData.hospital_name}
            placeholder="Hospital name"
            handleChange={handleInput}
            type="string"
            // success={signUpRules.hospital_name}
          />
          <InputDefault
            id={"license_number"}
            value={formData.license_number}
            placeholder="Physician's License Number"
            handleChange={handleInput}
            type="number"
            // success={signUpRules.license_number}
          />

          {/* </form> */}
        </div>
        <section className="flex justify-center gap-4 px-20 pt-8 pb-6 text-center">
          <ButtonDefault
            type="submit"
            value="SAVE"
            disabled={true}
            // handleClick={editDoctorInfo}
          />
        </section>
      </section>
    </BaseModal>
  );
}

export default EditDoctorInfoModal;
