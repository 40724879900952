import parse from "html-react-parser";

function SanitizedDiv({text}) {
    return (
        <div className="editor">
            {parse(text??'')}
        </div>
    );
}

export default SanitizedDiv;
