import "./index.css";

function SleeAppLegend({ title, data, border }) {
  return (
    <div
      className={`flex flex-col shadow-default w-fit h-max ${
        border ? "border border-gray-light" : ""
      }`}
    >
      <p className="uppercase bg-white w-full py-2 text-center text-xs">
        {title}
      </p>
      <div className="flex">
        {data.map((v, ind) => (
          <div key={ind} className="relative">
            <p
              className="py-2 px-2 text-white text-center select-none h-full w-[6rem]"
              style={{
                backgroundColor: v.color,
                fontSize: "12px",
                fontWeight: 900,
              }}
            >
              {v.tip}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SleeAppLegend;
