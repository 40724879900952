import CONSTANTS from "./constant";

export const getDays = (year, month) => {
  const curMonthFirstDay = new Date(year, month - 1, 1).getDay();
  const curMonth = new Date(year, month, 0);
  const noOfCurMonthDays = curMonth.getDate();

  let prevMonth = month - 1;
  let nextMonth = month + 1;

  let noOfLastMonthDays = 0;

  let prev = [];
  let next = [];

  if (prevMonth < 1) {
    noOfLastMonthDays = new Date(year - 1, 12, 0).getDate();
    prev = [year - 1, 12];
  } else {
    noOfLastMonthDays = new Date(year, prevMonth, 0).getDate();
    prev = [year, prevMonth];
  }

  if (nextMonth > 12) {
    next = [year + 1, 1];
  } else {
    next = [year, nextMonth];
  }

  let days = [];

  for (let i = 0; i < curMonthFirstDay; i++) {
    days = [
      {
        value: [...prev, noOfLastMonthDays - i],
        color: "text-gray-light-3",
      },
      ...days,
    ];
  }

  for (let i = 0; i < noOfCurMonthDays; i++) {
    days = [
      ...days,
      {
        value: [year, month, i + 1],
        color: "text-gray-dark-2",
      },
    ];
  }

  for (let i = 0; i < 6 - curMonth.getDay(); i++) {
    days = [
      ...days,
      {
        value: [...next, i + 1],
        color: "text-gray-light-3",
      },
    ];
  }

  return days;
};

export const isToday = (date) => {
  if (
    new Date().getFullYear() === date[0] &&
    new Date().getMonth() + 1 === date[1] &&
    new Date().getDate() === date[2]
  ) {
    return true;
  }

  return false;
};

export const getToday = () => {
  const d = new Date();

  return `${d.getFullYear()}/${d.getMonth() + 1}/${d.getDate()}`;
};

export const getDay = (date) => {
  const d = new Date(date);

  return CONSTANTS.DAYS[d.getDay()];
};

export const getTodayArray = () => {
  const d = new Date();

  return [d.getFullYear(), d.getMonth() + 1, d.getDate()];
};

export const getDateArray = (date) => {
  const d = new Date(date);

  return [d.getFullYear(), d.getMonth() + 1, d.getDate()];
};

export const getDateBeforeNDays = (days, dt = null) => {
  let a = new Date().getTime() - 1000 * 3600 * 24 * days;

  if (dt) {
    a = new Date(dt).getTime() - 1000 * 3600 * 24 * days;
  }

  return formatDateDash(new Date(a));
};

export const compareDates = (date1, date2) => {
  let separator = "/";

  if (date1.includes("-")) {
    separator = "-";
  }

  const d1 = date1.split(separator).map((val) => Number(val));
  const d2 = date2.split(separator).map((val) => Number(val));

  return d1[0] === d2[0] && d1[1] === d2[1] && d1[2] === d2[2];
};

export const formatTime = (date) => {
  // console.log("The date", date)
  date = new Date(date);
  let time = date.toLocaleTimeString();

  let ts = time.split(":");

  return `${ts[0]}:${ts[1]}${ts[2].split(" ")[1]}`;
};

export const formatHourMinute = (time) => {
  if (!time) {
    return time;
  }

  let hrMin = time?.split(":");

  return `${hrMin[0]}:${hrMin[1]}`;
};

export const formatDateFromArray = (date) => {
  let yr = date[0];
  let mn = date[1];
  let dt = date[2];

  return `${yr}/${mn < 10 ? `0${mn}` : mn}/${dt < 10 ? `0${dt}` : dt}`;
};

export const formatDateFromArrayDash = (date) => {
  let yr = date[0];
  let mn = date[1];
  let dt = date[2];

  return `${yr}-${mn < 10 ? `0${mn}` : mn}-${dt < 10 ? `0${dt}` : dt}`;
};

export const formatDate = (date) => {
  date = new Date(date);

  let mn = date.getMonth() + 1;
  let dt = date.getDate();

  return `${date.getFullYear()}/${mn < 10 ? `0${mn}` : mn}/${
    dt < 10 ? `0${dt}` : dt
  }`;
};

export const formatDateFile = (date) => {
  date = new Date(date);

  let mn = date.getMonth() + 1;
  let dt = date.getDate();

  return `${date.getFullYear() - 2000}${mn < 10 ? `0${mn}` : mn}${
    dt < 10 ? `0${dt}` : dt
  }`;
};

export const formatDateDot = (date) => {
  date = new Date(date);

  // console.log("The shown date", date, date.getDate())

  let mn = date.getMonth() + 1;
  let dt = date.getDate();

  return `${date.getFullYear()}.${mn < 10 ? `0${mn}` : mn}.${
    dt < 10 ? `0${dt}` : dt
  }`;
};

export const formatDateDash = (date) => {
  date = new Date(date);

  let mn = date.getMonth() + 1;
  let dt = date.getDate();

  return `${date.getFullYear()}-${mn < 10 ? `0${mn}` : mn}-${
    dt < 10 ? `0${dt}` : dt
  }`;
};

export const formatTimeDate = (dt) => {
  const date = new Date(dt);

  let time = formatTime(date);
  let dateFin = formatDate(date);

  return `${time}, ${dateFin}`;
};

export const getMinuteDiff = (dt) => {
  const date = new Date(dt);
  const now = new Date();

  return (date.getTime() - now.getTime()) / (1000 * 60);
};

export const formatSeconds = (secs) => {
  secs = Number(secs);
  let hrs = Math.floor(secs / 3600);

  let days = Math.floor(hrs / 24);

  hrs = hrs % 24;

  let leftSecs = secs % 3600;

  let mins = Math.floor(leftSecs / 60);

  if (days > 0) {
    return `${days}${days > 1 ? "일" : "일"} ${hrs}${
      hrs > 1 ? "시간" : "시간"
    } ${mins}${mins > 1 ? "분" : "분"}`;
  }

  if (hrs > 0) {
    return `${hrs}${hrs > 1 ? "시간" : "시간"} ${mins}${
      mins > 1 ? "분" : "분"
    }`;
  }

  return `${mins}${mins > 1 ? "분" : "분"}`;
};

export const newDate = (date) => {
  return new Date(date);
};

export const removeTimezone = (date, timezone = "Asia/Seoul") => {
  let a = new Date(date);
  // a = new Date(a.toLocaleString('en', {timeZone: timezone}))
  // return a
  return new Date(a.toLocaleString("en", { timeZone: timezone }));
  // return new Date(a.getTime()+(1000 * 60 * a.getTimezoneOffset()))
  // return new Date(a.getTime()+(1000 * 60 * 60 * 9))
};

export const addDaysToDate = (date, days) => {
  let a = new Date(date).getTime() + 1000 * 3600 * 24 * days;
  console.log("The date ", a, date);
  return formatDateDash(a);
};

export const getDatesBetween = (startDate, endDate) => {
  let start = new Date(startDate);
  let end = new Date(endDate);
  let dates = [];

  while (start <= end) {
    dates.push(formatDateMD(new Date(start)));
    start.setDate(start.getDate() + 1);
  }

  return dates;
};

export const formatDateMD = (date) => {
  date = new Date(date);
  let mn = date.getMonth() + 1;
  let dt = date.getDate();

  return `${mn < 10 ? `0${mn}` : mn}/${dt < 10 ? `0${dt}` : dt}`;
};
