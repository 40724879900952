import { newDate } from "../../lib/date";

export const getCodes = (data) => {
  let sample = [];
  let count = 0;

  let prev = data && data.length > 0 ? data[0] : null;

  for (let j = 0; j < data.length; j++) {
    let i = data[j];

    if (prev !== i || j + 1 >= data.length) {
      sample.push({
        code: prev,
        length: count,
      });

      count = 0;
      prev = i;
    }

    count++;
  }

  return sample;
};

export const getHour = (dt, forward) => {
  let c = 0;
  while (true) {
    if (forward) {
      dt = newDate(dt.getTime() + 1000 * 3600);
    } else {
      dt = newDate(dt.getTime() - 1000 * 3600);
    }

    if (dt.getHours() === 12) {
      dt.setHours(12, 0, 0);

      return dt;
    }

    if (c > 24) {
      return dt;
    }

    c += 1;
  }
};

export const getPadding = (secs, start = false, empty = false) => {
  let arrLength = Math.abs(Math.floor(secs / 30));
  let intervals = Math.abs(Math.floor(arrLength / 120));

  let remainder = arrLength % 120;

  let pads = [];
  for (let i = 0; i < intervals; i++) {
    pads.push({
      code: -1,
      length: 120,
    });

    if (start || i + 1 < intervals) {
      pads.push({
        code: -2,
        length: 1,
      });
    }
  }

  if (!start && !empty) {
    pads = [
      {
        code: -1,
        length: remainder,
      },
      {
        code: -2,
        length: 1,
      },
      ...pads,
    ];
  } else {
    pads.push({
      code: -1,
      length: remainder,
    });
  }

  return pads;
};

export const getHours = (zoomIn, startHour, endHour, interval) => {
  let hrs = [];

  let startHr = startHour ?? 0;
  let endHr = endHour ?? 24;

  if (zoomIn) {
    let j = 0;

    for (let i = startHr; j <= interval; i++) {
      if (i > 24) {
        i = 1;
      }

      let reg = "AM";

      if (i > 11 && i < 24) {
        reg = "PM";
      }

      hrs.push(`${i % 12}${reg}`);

      j++;
    }
  } else {
    for (let i = startHr; i < endHr; i++) {
      let reg = "PM";

      if (i > 11) {
        reg = "AM";
      }

      hrs.push(`${i % 12}${reg}`);
    }
  }

  return hrs;
};

export const getHrInterval = (start, end) => {
  let st = newDate(start).getTime();
  let en = newDate(end).getTime();

  return Math.ceil((en - st) / (1000 * 3600));
};

export const getStages = (inputData, empty = false) => {
  let st = new Date(inputData.start_time);
  let en = new Date(inputData.end_time);

  if (!inputData?.zoom) {
    st = getHour(st, false);
    en = getHour(st, true);
  } else {
    st.setMinutes(0, 0);

    if (en.getMinutes() > 0 || en.getSeconds() > 0) {
      en = newDate(en.getTime() + 1000 * 3600);
      en.setMinutes(0, 0);
    }
  }

  let stTime = newDate(inputData.start_time);
  let enTime = newDate(inputData.end_time);

  let diffFromStart = st.getTime() - stTime.getTime();
  let diffFromEnd = en.getTime() - enTime.getTime();

  let startPad = getPadding(diffFromStart / 1000, true, empty);
  let endPad = getPadding(diffFromEnd / 1000, false, empty);

  let sleepStages = getCodes(inputData.sleep_stages_orig);
  let apneaStages = getCodes(inputData.apnea_stages_orig);

  let sleepHrs = [...startPad, ...sleepStages, ...endPad];
  let apneaHrs = [...startPad, ...apneaStages, ...endPad];

  return [sleepHrs, apneaHrs, st.getHours(), en.getHours()];
};

export const getStagesAndHours = (rowData, empty = false) => {
  const [sleepStagesIn, apneaStagesIn, stHr, enHr] = getStages(rowData, empty);

  let interval = getHrInterval(rowData["start_time"], rowData["end_time"]);

  rowData["total_hours"] = rowData["zoom"] ? interval : 24;

  rowData["hours"] = rowData["zoom"]
    ? getHours(true, stHr, enHr, interval)
    : getHours(false);

  rowData["sleep_stages"] = sleepStagesIn;
  rowData["apnea_stages"] = apneaStagesIn;

  return rowData;
};
