function Title({ value, color, size, ...props }) {
  return (
    <div
      className={`text-start font-semibold uppercase ${
        color ? `text-${color}` : "text-gray-dark-2"
      } ${
        size === "small"
          ? "text-xl"
          : size === "xx-small"
          ? "text-sm font-medium"
          : size === "big"
          ? "text-3xl"
          : "text-2xl"
      }`}
    >
      {value}
    </div>
  );
}

export default Title;
