import { useState, useEffect } from "react";
import ButtonDefault from "../ButtonDefault";
import InputDefault from "../InputDefault";
import { send } from "react-ga";
import axios from "axios";

export default function Verify({
  handleInput,
  name,
  phoneNumber,
  signUpRules,
  code,
}) {
  const [sendCode, setSendCode] = useState(false);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [timer, setTimer] = useState(300); // 5분 = 300초

  // const [code, setCode] = useState("");

  const handleGetCode = async () => {
    const cleanedPhoneNumber = phoneNumber.replace(/-/g, "");
    setSendCode(true);
    setShowCodeInput(true);
    setTimer(300); // 타이머 초기화
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APNOTRACK_URL}/v1/hub/auth/doctor/send-code`,
        {
          phone_number: cleanedPhoneNumber,
        }
      );
    } catch (error) {
      console.error("코드 전송 중 오류 발생:", error);
    }
  };

  useEffect(() => {
    let interval = null;

    if (sendCode && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
      // setTimer(300);
      setSendCode(false);
    }

    return () => clearInterval(interval);
  }, [sendCode, timer]);

  return (
    <>
      <div className="flex justify-between gap-1">
        <InputDefault
          id={"name"}
          value={name}
          placeholder="Name"
          handleChange={handleInput}
          width={"w-full"}
          success={signUpRules.name}
        />
      </div>
      <div className="flex gap-1 justify-between">
        <InputDefault
          id="phone_number"
          type="tel"
          value={phoneNumber}
          placeholder="Phone Number"
          handleChange={handleInput}
          width="w-[60%]"
          success={signUpRules.phone_number}
        />
        <ButtonDefault
          width="small"
          // btnWidth={true}
          disabled={phoneNumber?.length !== 13 || !signUpRules?.name}
          value={showCodeInput ? "RESEND CODE" : "SEND CODE"}
          size="small"
          handleClick={handleGetCode}
        />
      </div>
      {showCodeInput && (
        <>
          <InputDefault
            id="code"
            type="string"
            value={code}
            placeholder="Enter Verification Code"
            handleChange={handleInput}
            success={code.length === 6}
          />
          <div>
            {
              <p className="text-[#1A2663] font-semibold">
                Verification code is valid for{" "}
                <span className={timer <= 10 ? `text-red` : ""}>
                  {/* <span> */}
                  {Math.floor(timer / 60)}:
                  {(timer % 60).toString().padStart(2, "0")}
                </span>
              </p>
            }
          </div>
        </>
      )}
    </>
  );
}
