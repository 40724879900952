import React, { useState, useEffect } from "react";
import ECharts from "echarts-for-react";

const AHIChart = ({ ahiData }) => {
  // const days = getDatesBetween(detailData.start_date, detailData.end_date);

  const [options, setOptions] = useState({
    tooltip: { trigger: "item", formatter: "{b} <br/>AHI : {c}" },
    width: "75%",
    xAxis: {
      type: "category",
      data: ahiData?.map((a) => a.day),
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: ahiData?.map((a) => a.ahi),
        type: "bar",
        barWidth: "20%",
      },
    ],
  });

  console.log(ahiData);

  return (
    <div className={`flex flex-col gap-5 w-[215mm] h-[500px] $`}>
      <h2 className="text-2xl uppercase font-medium text-black mt-[1.5rem]">
        ESTIMATED AHI
      </h2>
      {ahiData.length > 0 ? (
        <ECharts option={options} opts={{ renderer: "svg", height: "450" }} />
      ) : (
        <span className="flex bg-slate-100 py-1 h-full justify-center items-center opacity-60">
          Not Available.
        </span>
      )}
    </div>
  );
};

export default AHIChart;
